import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setSelectedPolygon, setSelectedPS } from '../../../state/polygonSlice';
import './sidebar.css';

const Sidebar = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);

  const handleMouseEnter = () => setIsOpen(true);
  const handleMouseLeave = () => setIsOpen(false);
  const handleBeatGraph = () => {
    dispatch(setSelectedPolygon(null));
    dispatch(setSelectedPS(null));
  }
  const handleClick = () => {
    handleMouseLeave();
    handleBeatGraph();
  };

  return (
    <>
      {/* Hamburger Icon */}
      <button
        className={`hamburger ${isOpen ? 'open' : ''}`}
        onMouseEnter={handleMouseEnter}
        aria-label="Toggle Sidebar"
        aria-expanded={isOpen}
      >
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </button>
      
      {/* Sidebar menu */}
      <div 
        className={`sidebar ${isOpen ? 'open' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <Link 
          to="/map/crime" 
          className={`sidebar-button ${location.pathname === '/map/crime' ? 'active' : ''}`}
          onClick={handleClick}
        >
          CRIME DATA
        </Link>
        <Link 
          to="/map/constable" 
          className={`sidebar-button ${location.pathname === '/map/constable' ? 'active' : ''}`}
          onClick={handleMouseLeave}
        >
          CONSTABLE LOCATION
        </Link>
        <Link 
          to="https://track.roadcast.co.in/app/home" 
          className={`sidebar-button ${location.pathname === '/' ? 'active' : ''}`}
          onClick={handleMouseLeave}
        >
          VEHICLE LOCATION
        </Link>
        <Link 
          to="/map/cctv" 
          className={`sidebar-button ${location.pathname === '/map/cctv' ? 'active' : ''}`}
          onClick={handleMouseLeave}
        >
          CCTV LOCATION
        </Link>
        <Link 
          to="/map/all" 
          className={`sidebar-button ${location.pathname === '/map/all' ? 'active' : ''}`}
          onClick={handleMouseLeave}
        >
          ALL
        </Link>
        <Link 
          to="/map/patrol" 
          className={`sidebar-button ${location.pathname === '/map/patrol' ? 'active' : ''}`}
          onClick={handleMouseLeave}
        >
          PATROLPATH
        </Link>
        <Link 
          to="/map/mark" 
          className={`sidebar-button ${location.pathname === '/map/mark' ? 'active' : ''}`}
          onClick={handleMouseLeave}
        >
          SUGGESTION MARKS
        </Link>
      </div>

      {/* Overlay to close sidebar when clicking outside */}
      {isOpen && (
        <div 
          className="overlay" 
          onClick={handleMouseLeave} 
          style={{ pointerEvents: 'auto' }}
        ></div>
      )}
    </>
  );
};

export default Sidebar;
